import { useState } from 'react';

import { eventEmitter } from './useEventEmitter';

const useSocketListener = <T>(event: string): [T | null] => {
	const [data, setData] = useState<T | null>(null);

	const eventCallback = (eventData: T) => {
		setData(eventData);
	};

	eventEmitter.on(event, eventCallback);

	return [data];
};

export { useSocketListener };
