import React from 'react';
import ReactDOM from 'react-dom/client';
// eslint-disable-next-line import/order
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import './index.css';
import './fonts/Figtree/static/Figtree-SemiBold.ttf';

import App from './App';
import reportWebVitals from './reportWebVitals';

Sentry.init({
	dsn: 'https://39eb565adc6f448282784df2cfd80ea0@o4504723158663168.ingest.sentry.io/4504724138360832',
	integrations: [new BrowserTracing()],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
