import * as d3 from 'd3';
import React from 'react';

import { IGradientProps } from '../../utils/globalTypes';

const XAxisGradient = ({ color, type }: IGradientProps) => {
	const ref = React.useRef<any>(null);

	d3.select(ref.current).attr('x1', '0%').attr('x2', '100%').attr('y1', '0%').attr('y2', '0%');

	return (
		<defs>
			<linearGradient ref={ref} id={`xAxisGradient-${type}`} gradientUnits={'userSpaceOnUse'}>
				<stop offset={'0%'} style={{ stopColor: 'black', stopOpacity: 0 }} />
				<stop offset={'10%'} style={{ stopColor: color, stopOpacity: 1 }} />
				<stop offset={'90%'} style={{ stopColor: color, stopOpacity: 1 }} />
				<stop offset={'100%'} style={{ stopColor: 'black', stopOpacity: 0 }} />
			</linearGradient>
		</defs>
	);
};

export default XAxisGradient;
