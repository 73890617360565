import React from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import { IDimensions } from './globalTypes';

/**
 * Use ResizeObserver to listen for the changes to the dimensions of an element
 * @params ref - React ref
 * @returns {object} - { width, height, top, left }
 */

const useResize = (ref: React.MutableRefObject<any>) => {
	const [dimensions, setDimensions] = React.useState<IDimensions>();
	React.useEffect(() => {
		const element = ref.current;
		const resizeObserver = new ResizeObserver((entries: any) => {
			entries.forEach((entry: any) => {
				setDimensions(entry.contentRect);
			});
		});
		resizeObserver.observe(element);
		return () => {
			resizeObserver.unobserve(element);
		};
	}, [ref]);
	return (
		dimensions || {
			width: 0,
			height: 0,
			top: 0,
			left: 0,
		}
	);
};

export default useResize;
