import * as d3 from 'd3';
import React from 'react';

const CurrentSituationBackground = ({ height, type, progress, ...props }: ICurrentSituationBackgroundProps) => {
	const ref = React.useRef<any>(null);
	const position = `${progress}%`;

	// grey zone of the history
	d3.select(ref.current)
		.attr('class', `current-situation-background-${type}`)
		.style('fill', 'black')
		.style('opacity', 0.5)
		.attr('width', `calc(${position} - 0.15%)`)
		.attr('height', height)
		.attr('rx', 5)
		.attr('ry', 5)
		.attr('x', 0)
		.raise();

	return <rect ref={ref} className="currentSituationBackground" {...props} />;
};

interface ICurrentSituationBackgroundProps {
	width: number;
	height: number;
	progress: number;
	type: string;
}

export default CurrentSituationBackground;
