import React from 'react';

import './App.css';
import Dashboard from './Dashboard';
import ConfigProvider from './providers/ConfigProvider';

const ISStyle = React.lazy(() => import('./InteractiveScapeStyle'));

function App() {
	const groupId =
		window.location.pathname.split('/')[1] || new URLSearchParams(window.location.search).get('group') || '';
	const isInteraciveScape =
		new URLSearchParams(window.location.search).get('isinteracivescape')?.toLowerCase() === 'true';

	return (
		<ConfigProvider>
			<>
				{isInteraciveScape && <ISStyle />}
				<Dashboard group={groupId} />
			</>
		</ConfigProvider>
	);
}

export default App;
