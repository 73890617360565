/* eslint-disable indent */

import React from 'react';
import * as d3 from 'd3';

import { BORDER_TYPE, IBorderProps } from '../../utils/globalTypes';

const Border = ({ width, height, type, color, ...props }: IBorderProps) => {
	const ref = React.useRef<any>(null);

	const border = d3.select(ref.current).raise();

	switch (type) {
		case BORDER_TYPE.top:
			border.attr('width', width);
			border.attr('height', 0.1);
			border.attr('y', 1);
			border.attr('x', 0);
			break;
		case BORDER_TYPE.left:
			border.attr('width', 0.1);
			border.attr('height', height);
			border.attr('y', 0);
			border.attr('x', 1);
			break;
		case BORDER_TYPE.right:
			border.attr('width', 0.1);
			border.attr('height', height);
			border.attr('y', 0);
			border.attr('x', width - 1);
			break;
		case BORDER_TYPE.bottom:
			border.attr('width', width);
			border.attr('height', 0.1);
			border.attr('y', height - 1);
			border.attr('x', 0);
			break;
		default:
			break;
	}

	return (
		<rect
			ref={ref}
			className={`border-${BORDER_TYPE[type]}`}
			stroke={color || '#3D3D3D'}
			strokeWidth={2}
			opacity={1}
			fill={'none'}
			width={width}
			height={height}
			{...props}
		/>
	);
};

export default Border;
