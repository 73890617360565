export enum DATA_TYPE {
	'past',
	'future',
}

export enum BORDER_TYPE {
	'top',
	'bottom',
	'left',
	'right',
}

export interface IDimensions {
	width: number;
	height: number;
	top: number;
	left: number;
}

export interface IMargin {
	top?: number;
	left?: number;
	bottom?: number;
	right?: number;
}

export interface IDimensionsProps {
	maxHeight?: number;
	margin?: IMargin;
	scaleCoef?: number;
}

export interface IScale {
	xScale?: (number: number) => void;
	yScale: (number: number) => void;
}

export interface ILineProps extends IScale {
	id: string;
	progress: number;
	data: {
		value: number | string;
	}[];
	type: DATA_TYPE;
	color: string;
	isSmooth?: boolean;
	start?: number;
	width: number;
	logo?: string;
	legend?: string;
	dashed?: boolean;
	[key: string]: unknown;
}

export interface IStackedAreaProps extends IScale {
	progress: number;
	data: IData[];
	isSmooth?: boolean;
	start?: number;
	width: number;
	type: DATA_TYPE;
}

export interface IStackedBarProps {
	data: IData[];
	width: number;
}

export interface ILegendProps {
	title: string;
	data: IData[];
	currentDate: Date;
	width: number;
	priceData?: IData;
	costPrice?: number;
}

export interface IBarChartProps {
	data: IData;
	width?: number;
	[key: string]: unknown;
}

export interface IGradientProps {
	color: string;
	type?: string;
	width?: number;
}

export interface IGraphData {
	value: number | string;
	timestamp?: Date;
	type?: string;
}

export interface IData {
	type: string;
	legend: string;
	brand?: string;
	charger?: string;
	stroke: string;
	fill?: string;
	logo?: string;
	items: IGraphData[];
	filename: string;
	isDc?: boolean;
}

export interface IBorderProps {
	width: number;
	height: number;
	type: BORDER_TYPE;
	color?: string;
}
