import * as d3 from 'd3';
import React from 'react';

import { IGradientProps } from '../../utils/globalTypes';

const StackedAreaGradient = ({ color, type }: IGradientProps) => {
	const ref = React.useRef<any>(null);

	d3.select(ref.current).attr('x1', '0%').attr('x2', '0%').attr('y1', '0%').attr('y2', '100%');

	return (
		<defs>
			<linearGradient ref={ref} id={`stackedAreaGradient-${type}`}>
				<stop offset={'0%'} style={{ stopColor: color, stopOpacity: 0.5 }} />
				<stop offset={'100%'} style={{ stopColor: 'black', stopOpacity: 0.3 }} />
			</linearGradient>
		</defs>
	);
};

export default StackedAreaGradient;
