/* eslint-disable indent */
import React, { useEffect } from 'react';
import * as d3 from 'd3';

import { IData, IGraphData, IStackedBarProps } from '../../utils/globalTypes';

const StackedBar = ({ data, width }: IStackedBarProps) => {
	const mappedData: Array<{ [key: string]: number }> = [];

	const actualWidth = width;
	const actualStartingPoint = 0;

	if (!data) {
		return null;
	}

	data.forEach(({ filename, items: stackedData }: IData) => {
		stackedData.forEach((el: IGraphData, key: number) => {
			if (mappedData[key]) {
				mappedData[key][filename] = el.value as number;
			} else {
				mappedData[key] = { [filename]: el.value as number };
			}
		});
	});

	if (!mappedData.length) return null;

	const stack = d3.stack().keys(Object.keys(mappedData[0]));
	const stackedData = stack(mappedData);

	const xScale = React.useMemo(
		() =>
			d3
				.scaleLinear()
				.domain([0, data[0].items.length - 1])
				.range([actualStartingPoint, width]),
		[actualStartingPoint, actualWidth, width],
	);

	/* // @ts-ignore
	const drawArea = d3
		.area()
		.x((d, i) => xScale(i))
		.y0((d) => yScale(d[0]))
		// @ts-ignore
		.y1((d: GraphData) => yScale(d[1]));
 */
	// Draw the lines
	// @ts-ignore
	/* const drawLine = d3
		.area()
		.x((d, i) => xScale(i))
		// @ts-ignore
		.y0((d) => {
			return yScale(d[1]);
		})
		// @ts-ignore
		.y1((d: GraphData) => yScale(d[1])); */

	useEffect(() => {
		stackedData.forEach((d, i) => {
			d3.select(`#stackedBar-${i}`)
				.lower()
				.style('fill', `url(#stackedBarGradient-${data[i].type}`)
				/* .transition()
				.duration(1000) */
				// @ts-ignore
				.attr('d', drawArea(d));
		});
	}, [data]);

	return (
		<>
			{stackedData.map((d, i) => {
				return (
					<React.Fragment key={`stackedBar-${i}`}>
						<path id={`stackedBar-${i}`} className="stackedBar" />
					</React.Fragment>
				);
			})}
		</>
	);
};

StackedBar.defaultProps = {
	data: [],
	color: 'white',
	isSmooth: true,
	start: 0,
};

export default StackedBar;
