import * as d3 from 'd3';
import React from 'react';

const Fade = ({ width, height }: { width: number; height: number }) => {
	const ref = React.useRef<any>(null);

	d3.select(ref.current).attr('x1', '0%').attr('x2', '100%').attr('y1', '0%').attr('y2', '0%');

	return (
		<>
			<defs>
				<linearGradient ref={ref} id={`multiLineChart-fade`}>
					<stop offset={'0%'} style={{ stopColor: 'black', stopOpacity: 1 }} />
					<stop offset={'15%'} style={{ stopColor: 'black', stopOpacity: 0 }} />
					<stop offset={'85%'} style={{ stopColor: 'black', stopOpacity: 0 }} />
					<stop offset={'100%'} style={{ stopColor: 'black', stopOpacity: 1 }} />
				</linearGradient>
			</defs>
			<rect width={width} height={height} style={{ fill: 'url(#multiLineChart-fade)' }}></rect>
		</>
	);
};

export default Fade;
