import { io, Socket } from 'socket.io-client';
import { useContext, useEffect, useState } from 'react';

import { ConfigContext } from '../providers/ConfigProvider';

import { eventEmitter } from './useEventEmitter';

const useSocketEmitter = () => {
	const [isSocketOpen, setIsSocketOpen] = useState(false);
	const [webSocket, setWebSocket] = useState<Socket | null>(null);

	const { API_WSS } = useContext(ConfigContext);

	const maxRetries = 10;
	const disabled = !!process.env.REACT_APP_DISABLE_SOCKET;
	let socketInterval: NodeJS.Timer | null = null;
	let retries = 0;

	useEffect(() => {
		if (disabled || !API_WSS) {
			return;
		}

		connect();
		startConnectionInterval();
	}, [API_WSS]);

	const startConnectionInterval = () => {
		if (socketInterval) {
			clearInterval(socketInterval);
			socketInterval = null;
		}

		socketInterval = setInterval(() => {
			if (retries >= maxRetries) {
				socketInterval && clearInterval(socketInterval);
				return;
			}

			if (!isSocketOpen) {
				reconnect();
				retries = retries + 1;
			}
		}, 10000);
	};

	const connect = async (): Promise<boolean> => {
		if (!API_WSS) {
			return false;
		}

		const wsUrl = API_WSS;
		const ws = io(wsUrl, {
			transports: ['websocket'],
			autoConnect: true,
		});
		setWebSocket(ws);

		return new Promise((resolve, reject) => {
			ws.on('connect', () => {
				console.log('ws connected');

				setIsSocketOpen(true);
				socketInterval && clearInterval(socketInterval);
				retries = 0;

				resolve(true);
			});

			ws.onAny((event: string, eventData: any) => {
				console.log('All WebSocket messages received:', event, eventData); // Log all incoming messages
				let data: any;
				try {
					if (eventData && typeof eventData === 'string') {
						data = JSON.parse(eventData);
					} else {
						data = eventData;
					}
					eventEmitter.emit(event, data);
					console.log(`Event received: ${event}`, data);
				} catch (e) {
					console.error('Failed parsing websocket event', e);
				}
			});

			ws.on('connect_error', (err: any) => {
				console.log('ws connect error', err);
			});
			ws.on('error', (err: any) => {
				console.log('ws error', err);
			});

			ws.on('disconnect', () => {
				console.log('ws disconnected');

				setIsSocketOpen(false);
				startConnectionInterval();

				reject(false);
			});
		});
	};

	const reconnect = async () => {
		try {
			await connect();
		} catch (err) {
			console.log('ws could not reconnect');
		}
	};

	const emitToServer = (event: string, data: any) => {
		if (!disabled && webSocket !== null) {
			webSocket.emit(event, data);
		}
	};

	return { emitToServer, isSocketOpen };
};

export { useSocketEmitter };
