//@ts-nocheck
// Credit: https://stackblitz.com/@hungdev
class EventEmitter {
	constructor() {
		this.events = {};
	}

	on(event, listener) {
		if (!this.events[event]) {
			this.events[event] = [];
		}

		this.events[event].push(listener);
	}

	emit(event, payload) {
		if (this.events[event]) {
			console.log(`Emitting event: ${event}`, payload);
			this.events[event].forEach((listener) => {
				listener(payload);
			});
		}
		else {
			console.log(`No listeners for event: ${event}`);
		}
	}

	// Method to remove a specific listener for an event
	removeListener(event, listenerToRemove) {
		if (!this.events[event]) {
			return;
		}

		this.events[event] = this.events[event].filter(listener => listener !== listenerToRemove);
	}

}

export const eventEmitter = new EventEmitter();
