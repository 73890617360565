import React, { ReactElement, createContext, useCallback, useEffect, useState } from 'react';

import { IGlobalConfig } from './config.types';

// The DEFAULT_CONFIG sets up the globalConfig with all props set to null.
const DEFAULT_CONFIG: IGlobalConfig = {
	API_WSS: null,
};

// Set up a new ConfigContext that uses the above default config
export const ConfigContext = createContext(DEFAULT_CONFIG);

// The ConfigProvider component wraps the app and provides the config through context
const ConfigProvider: React.FC<{ children: ReactElement }> = ({ children }) => {
	// Set up config/setConfig through useState
	const [config, setConfig] = useState(DEFAULT_CONFIG);

	// The getConfig function will fetch the local app.settings.json file and process the response
	// useCallback is important to avoid infinite loops in useEffects
	const getConfig = useCallback(async () => {
		const result = await fetch('./config/app.settings.json', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		}).then((response) => response.json());

		// Store the config to a custom globalConfig object on the window to allow usage outside of React.
		window.globalConfig = result;

		// Set the config
		setConfig(result);
	}, []);

	// Set up useEffect to trigger a getConfig
	useEffect(() => {
		getConfig();
	}, [getConfig]);

	return (
		// Provide the config to the React Context
		<ConfigContext.Provider value={config}>
			{config.API_WSS ? children : <div style={{ color: 'white' }}>Loading...</div>}
		</ConfigContext.Provider>
	);
};

export default ConfigProvider;
