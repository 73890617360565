/* eslint-disable indent */
import React, { useEffect } from 'react';
import * as d3 from 'd3';

import { DATA_TYPE, IData, IGraphData, IStackedAreaProps } from '../../utils/globalTypes';

const StackedArea = ({ yScale, progress, data, isSmooth, start = 0, width, type }: IStackedAreaProps) => {
	const mappedData: Array<{ [key: string]: number }> = [];

	const actualWidth = width * (start === 0 ? progress / 100 : 1);
	const actualStartingPoint = width * (start / 100);

	if (!data) {
		return null;
	}

	data.forEach(({ filename, items: stackedData }: IData) => {
		stackedData.forEach((el: IGraphData, key: number) => {
			if (mappedData[key]) {
				mappedData[key][filename] = el.value as number;
			} else {
				mappedData[key] = { [filename]: el.value as number };
			}
		});
	});

	if (!mappedData.length) return null;

	const stack = d3.stack().keys(Object.keys(mappedData[0]));
	const stackedData = stack(mappedData);

	const xScale = React.useMemo(
		() =>
			d3
				.scaleLinear()
				.domain([0, data[0].items.length - 1])
				.range([actualStartingPoint, start === 0 && type === DATA_TYPE.past ? actualWidth : width]),
		[actualStartingPoint, actualWidth, width],
	);

	// @ts-ignore
	const drawArea = d3
		.area()
		.x((d, i) => xScale(i))
		.y0((d) => yScale(d[0]))
		// @ts-ignore
		.y1((d: GraphData) => yScale(d[1]));

	isSmooth && drawArea.curve(d3.curveBasis);

	// Draw the lines
	// @ts-ignore
	const drawLine = d3
		.area()
		.x((d, i) => xScale(i))
		// @ts-ignore
		.y0((d) => {
			return yScale(d[1]);
		})
		// @ts-ignore
		.y1((d: GraphData) => yScale(d[1]));

	isSmooth && drawLine.curve(d3.curveBasis);

	useEffect(() => {
		stackedData.forEach((d, i) => {
			d3.select(`#area-${i}-${type}`)
				.lower()
				.style('fill', `url(#stackedAreaGradient-${data[i].filename}`)
				// @ts-ignore
				.attr('d', drawArea(d));

			// @ts-ignore
			const currentLine = d3.select(`#line-${d.key}-${type}`).attr('d', drawLine(d));

			if (i !== 0) {
				// lower everything except uncontrollable load
				currentLine.lower();
			}
		});
	}, [data, progress]);

	return (
		<>
			{stackedData.map((d, i) => {
				return (
					<React.Fragment key={`area-${i}-${type}`}>
						<path id={`area-${i}-${type}`} className="area" />
						<path
							id={`line-${d.key}-${type}`}
							className={`line line-${d.key}-${type}`}
							strokeWidth={'.15%'}
							fill={'none'}
							stroke={data[i].stroke}
						/>
					</React.Fragment>
				);
			})}
		</>
	);
};

StackedArea.defaultProps = {
	data: [],
	color: 'white',
	isSmooth: true,
	start: 0,
};

export default StackedArea;
