import React, { FC } from 'react';
import './QRCode.css';

const QRcode: FC<{ group: string; overlay?: boolean }> = ({ group, overlay = true }) => {
	const QRInfo = 'Follow along on your own device';

	return (
		<>
			{overlay && <div className="overlay"></div>}

			<div className="container">
				<div className="qr-code">
					<img src={`/api/v1/simulation/qr?group=${group}`} alt="QR Code" />
				</div>

				<div className="top-bottom">
					<div className="top">{QRInfo}</div>
					<div className="bottom">{QRInfo}</div>
				</div>
				<div className="left-right">
					<div className="left">{QRInfo}</div>
					<div className="right">{QRInfo}</div>
				</div>
			</div>
		</>
	);
};

export default QRcode;
