import React from 'react';

import { CurrentSituationLine, XAxis, XAxisGradient } from '../../components';
import useDimensions from '../../utils/useDimensions';

import { WeatherChartProps } from './WeatherChart.types';

const WeatherChart = ({ data, currentDate, progress, maxHeight, margin = {}, yScale }: WeatherChartProps) => {
	const svgRef = React.useRef<any>(null);
	const [containerRef, { svgWidth, height }] = useDimensions({
		maxHeight,
		margin,
	});

	const currentHours = currentDate.getHours();
	const currentMinutes = currentDate.getMinutes();
	const currentTime = `${currentHours.toString().length > 1 ? currentHours : '0' + currentHours}:${
		currentMinutes.toString().length > 1 ? currentMinutes : '0' + currentMinutes
	}`;

	const currentValueIndex = data.items.findIndex((item) => {
		const date = new Date(item.timestamp as unknown as string);
		const hour = date.getUTCHours();
		const minutes = date.getUTCMinutes();

		return hour === currentDate.getHours() && minutes === currentDate.getMinutes();
	});
	const currentValue = data.items[currentValueIndex]?.value;

	return (
		<div className="chartContainer" ref={containerRef} style={{ height: height }}>
			<svg width={svgWidth} height={height} ref={svgRef} style={{ overflow: 'visible' }}>
				<XAxis data={data} width={svgWidth} height={height} currentTime={currentTime} yScale={yScale} />
				<XAxisGradient color="white" type="weather" />
			 </svg>
		</div>
	);

};

WeatherChart.defaultProps = {
	data: [],
	margin: {
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
	},
	maxHeight: 150,
};


export default WeatherChart;
