import React from 'react';
import * as d3 from 'd3';

import { DATA_TYPE, ILineProps } from '../../utils/globalTypes';

const Line = ({
	yScale,
	progress,
	id,
	data,
	color,
	type,
	isSmooth,
	start = 0,
	width,
	dashed,
	...props
}: ILineProps) => {
	const ref = React.useRef<any>(null);

	const actualWidth = width * (start === 0 ? progress / 100 : 1);
	const actualStartingPoint = width * (start / 100);

	const xScale = React.useMemo(
		() =>
			d3
				.scaleLinear()
				.domain([0, data.length - 1])
				.range([actualStartingPoint, start === 0 && type === DATA_TYPE.past ? actualWidth : width]),
		[actualStartingPoint, actualWidth, width],
	);

	// @ts-ignore
	const drawLine = d3
		.line()
		.x((d, i) => xScale(i))
		// @ts-ignore
		.y((d) => yScale(d.value));

	isSmooth && drawLine.curve(d3.curveBasis);

	// @ts-ignore
	d3.select(ref.current).attr('d', drawLine(data));

	return (
		<>
			<path
				id={`line-${id}`}
				className={`line line-${id}`}
				ref={ref}
				stroke={'#00999F'}
				strokeWidth={'.15%'}
				strokeDasharray={dashed ? '10, 10' : ''}
				fill={'none'}
				{...props}
			/>
		</>
	);
};

Line.defaultProps = {
	data: [],
	color: 'white',
	isSmooth: true,
	start: 0,
};

export default Line;
