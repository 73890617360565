import * as d3 from 'd3';
import React from 'react';

const CurrentSituationLine = ({
	width,
	height,
	type,
	progress,
	color,
	icon,
	label,
	...props
}: ICurrentSituationLineProps) => {
	const lineRef = React.useRef<any>(null);
	const backgroundRef = React.useRef<any>(null);
	const iconRef = React.useRef<any>(null);
	const labelRef = React.useRef<any>(null);

	const lineHeight = icon && label ? height - width * 0.06 : icon || label ? height * 0.4 : height;
	const lineY = icon && label ? width * 0.025 : icon || label ? height * 0.28 : 0;

	d3.select(lineRef.current)
		.attr('class', `current-situation-${type}`)
		.style('fill', color ? color : `url(#currentSituationLineGradient-${type})`)
		.style('opacity', 1)
		.attr('width', '0.3%')
		.attr('height', lineHeight / 1.3)
		.attr('x', `calc(${progress}% - 0.15%)`)
		.attr('y', lineY)
		.attr('rx', '0.15%')
		.raise();

	d3.select(backgroundRef.current)
		.attr('class', `current-situation-${type}`)
		.style('fill', 'black')
		.style('opacity', 1)
		.attr('width', '0.3%')
		.attr('height', lineHeight / 1.3)
		.attr('x', `calc(${progress}% - 0.15%)`)
		.attr('y', lineY)
		.attr('rx', '0.15%')
		.raise();

	d3.select(iconRef.current)
		.style('fill', 'red')
		.attr('x', `calc(${progress}% - 1%)`)
		.attr('y', 0)
		.attr('width', '2%')
		.raise();

	d3.select(labelRef.current)
		//.style('left', `calc(${position}px - 5px)`)
		.attr('y', '85%')
		.attr('x', `${progress}%`)
		.attr('font-size', width * 0.0125)
		.raise();

	d3.select(lineRef.current).raise();

	return (
		<>
			{
				/* eslint-disable react/no-unknown-property */

				icon && (
					<image ref={iconRef} xlinkHref={`assets/${icon}_white.svg`} fill="white" textAnchor={'middle'} />
				)
			}
			<rect ref={lineRef} className="currentSituationLine" />
			<rect ref={backgroundRef} className="currentSituationLineBackground" {...props} />
			{label && (
				<text ref={labelRef} x={`${progress}%`} y={height - 20} textAnchor={'middle'} fill="white">
					{label}
				</text>
			)}
		</>
	);
};

interface ICurrentSituationLineProps {
	width: number;
	height: number;
	progress: number;
	type: string;
	color?: string;
	icon?: string;
	label?: string;
	[key: string]: any;
}

export default CurrentSituationLine;
