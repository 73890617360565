import { useEffect, useRef, useState } from 'react';

import useWindowDimensions from '../hooks/useWindowDimensions';

import useResize from './useResize';
import { IDimensionsProps } from './globalTypes';

const useDimensions = ({ maxHeight, margin = {}, scaleCoef = 1 }: IDimensionsProps) => {
	const ref = useRef(null);
	const { width } = useResize(ref);
	const { height: windowHeight } = useWindowDimensions();

	const calculateDimensions = () => {
		const calculatedHeight = !maxHeight || width * scaleCoef < maxHeight ? width * scaleCoef : maxHeight;
		const innerWidth = width - (margin.left || 0) - (margin.right || 0);
		const innerHeight = calculatedHeight - (margin.top || 0) - (margin.bottom || 0);

		return {
			svgWidth: width,
			svgHeight: calculatedHeight,
			width: innerWidth,
			height: innerHeight,
		};
	};

	const [dimensions, setDimensions] = useState(calculateDimensions());

	useEffect(() => {
		// Call the function to calculate dimensions when the width or height changes.
		setDimensions(calculateDimensions());
	}, [width, windowHeight]);

	return [ref, { ...dimensions }] as const;
};

export default useDimensions;
