import * as d3 from 'd3';
import React from 'react';

import { Y_MIN_VALUE, Y_MAX_VALUE } from '../../constants';

export const renderAxis = ({ svgRef, width, height }: IAxisProps) => {
	// Create root container where we will append all other chart elements
	const svgEl = d3.select(svgRef.current);
	svgEl.selectAll('.axisObject').remove();
	const svg = svgEl.append('g').attr('class', 'axisObject');

	const y = d3.scaleLinear().domain([-70, 70]).range([height, 0]);

	const yAxis = d3
		.axisRight(y)
		.ticks(5)
		.tickValues([-40, 0, 40, 70])
		.tickFormat((d) => d + ' kW');

	// Render the yAxis
	const yAxisGroup = svg.append('g').attr('class', 'y-axis').call(yAxis);

	yAxisGroup
		.selectAll('text')
		.each(function (d, i) {
			if (i === 4) {
				return d3.select(this).attr('transform', `translate(${width * 0.008},${width * 0.006})`);
			}

			if (i === 3) {
				return d3.select(this).attr('transform', `translate(${width * 0.008},${width * 0.004})`);
			}

			// if (i === 1) {
			// 	return d3.select(this).attr('transform', `translate(${width * 0.008},-${width * 0.004})`);
			// }

			if (i === 0) {
				return d3.select(this).attr('transform', `translate(${width * 0.008},-${width * 0.006})`);
			}

			d3.select(this).attr('transform', `translate(${width * 0.008},0)`);

			// if (i === 0) {
			// 	d3.select(this).attr('transform', `translate(${width * 0.008},-${width * 0.008})`);
			// } else if (i === 4) {
			// 	d3.select(this)
			// 		.attr('transform', `translate(${width * 0.008},${width * 0.008})`)
			// 		.text('70 kW FUSE');
			// } else {
			// 	d3.select(this).attr('transform', `translate(${width * 0.008},0)`);
			// }
		})
		.attr('color', '#727272')
		.attr('font-size', width * 0.008)
		.lower();

	yAxisGroup
		.selectAll('line')
		.each(function (d, i) {
			if (i === 4) {
				//if (i === 11) {
				return d3.select(this).attr('stroke', 'url(#xAxisGradient-capacity)');
			}

			//if (i === 10) {
			if (i === 3) {
				return d3.select(this).style('stroke-dasharray', '3, 3');
			}

			d3.select(this).attr('display', 'none');
		})
		.lower()
		.style('stroke-width', '.1%')
		.attr('stroke', 'url(#xAxisGradient-capacity)')
		.attr('x1', 0)
		.attr('x2', width);

	svg.selectAll('.domain').remove();
};

interface IAxisProps {
	svgRef: React.MutableRefObject<null>;
	width: number;
	height: number;
	//yScale: d3.ScaleLinear<number, number, never>;
}
